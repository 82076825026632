import { faCheck, faCheckCircle, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import VerifiedUserModal from '../modals/VerifiedUser.modal'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import UserModal from '../modals/User.modal'

interface IFilterTable {
    tableData: any
    currentPage: number
    itemsPerPage: number
    shiftId: any
}

const FilterTable = ({ tableData, currentPage, itemsPerPage, shiftId }: IFilterTable) => {

    const [showModal, setShowModal] = useState<any>(undefined);

    const [showUserModal, setShowUserModal] = useState<any>(undefined)

    function isUserVerified(user: any) {
        const details = tableData && tableData?.verifications?.find((verified: any) => verified?.student?._id === user?._id);
        if (!details) {
            return false;
        }
        for (let index = 0; index < details?.verificationDetails?.length; index++) {
            if (details?.verificationDetails[index]?.success) {
                return true;
            }
        }
        return false;
    }

    function hasUserPhoto(user: any) {
        const verificationDetails = tableData && tableData?.verifications?.find((verified: any) => verified?.student?._id === user?._id);
        return verificationDetails?.userLivePhotoExists ? true : false;
    }

    return (
        <>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Email/Name</th>
                        <th>Photo</th>
                        <th>Roll.No</th>
                        <th>Verification</th>
                        <th>Mobile</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Center_Preference</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tableData ? tableData?.users?.map((data: any, index: number) => {
                        const verfiedUsers = tableData && tableData?.verifications?.find((verified: any) => verified?.student?._id === data?._id);
                        return (
                            <>
                                <tr>
                                    <td>{currentPage * itemsPerPage - itemsPerPage + index + 1}</td>
                                    <td onClick={() => { setShowModal(data._id) }} className="cursor-pointer">{data && data.email ? data.email : data?.name ? data?.name : "--"}</td>
                                    <td>{data && hasUserPhoto(data) ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />}</td>
                                    <td>{data && data.rollNo ? data.rollNo : "--"}</td>
                                    <td>{isUserVerified(data) ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />}</td>
                                    <td>{data && data.mobileNo ? data.mobileNo : "--"}</td>
                                    <td>{data && data.state ? data.state : "--"}</td>
                                    <td>{data && data.city ? data.city : "--"}</td>
                                    <td>{data && data.centerPreference ? data.centerPreference : "--"}</td>
                                    <td><FontAwesomeIcon icon={faEye} className="text-primary" onClick={() => setShowUserModal(data._id)} /></td>
                                </tr>
                            </>
                        )
                    }) : "No data found"}
                </tbody>
            </Table>
            <VerifiedUserModal
                show={showModal ? true : false}
                handleClose={() => setShowModal(undefined)}
                modalData={
                    tableData && tableData?.verifications?.find((verified: any) => verified?.studentId === showModal)
                }
            />

            <UserModal
                show={showUserModal}
                handleClose={() => setShowUserModal(undefined)}
                shiftId={shiftId}
            />
        </>
    )
}

export default FilterTable