import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Pagination, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import Select from "react-select"
import { UsersCard } from '../../component/Card/Statistics.card';
import { ClientService } from '../../component/services/client.service';
import CsrReportTable from '../../component/table/CsrReportTable';
import downloadjs from 'downloadjs';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import UserModal from '../../component/modals/User.modal';

const Csr = () => {


    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const [countData, setCountData] = useState<any>();
    const [verificationData, setVerificationData] = useState<any>();
    const [userData, setUserData] = useState<any>(undefined)
    const [showUserModal, setShowUserModal] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false);

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<any>({
        label: "10",
        value: 10
    });
    const [totalCount, setTotalCount] = useState<number>(0);

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }
    const getCountData = async () => {
        await ClientService.getCountInfo(selectValue)
            .then((res) => {
                if (res.status === 200) {
                    setCountData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            });
    };

    const getVerificationData = async () => {
        await ClientService.getVerificationData(selectValue, pageNumber, pageSize.value)
            .then((res) => {
                if (res.status === 200) {
                    setVerificationData(res.data?.data?.verification);
                    setTotalCount(res.data?.data?.total_count)
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            });
    };

    const downloadVerifiedZip = async () => {
        setLoading(true);
        await ClientService.downloadVerifiedZip(selectValue)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    downloadjs(res.data, "verified.zip", "application/zip");
                }
            }).catch(e => {
                console.log(e);
                setLoading(false)
            })
    }



    useEffect(() => {
        if (selectValue) {
            setVerificationData([])
            getCountData();
            getVerificationData();
        }
    }, [selectValue, pageNumber, pageSize])

    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <div className="d-flex justify-content-start pt-4 mb-4">
                <Select
                    options={shifts}
                    onChange={(e: any) => setSelectValue(e.value)}
                    placeholder="Select shift"
                />
                <Button className="ms-4" size="sm" onClick={downloadVerifiedZip} disabled={loading}>Download Verified Candidates Zip</Button>
                <Button className="ms-4" size="sm" onClick={() => {
                    getCountData();
                    getVerificationData();
                }}
                >Refresh Data <FontAwesomeIcon icon={faRefresh} /></Button>

            </div>

            <Row>
                <Col md={3} className="me-4">
                    <UsersCard
                        reactIcon={
                            <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                        }
                        header="Biometric"
                        statsValue={
                            countData?.biometricCount
                                ? countData?.biometricCount
                                : 0
                        }
                    />
                </Col>{" "}
                <Col md={3} className="me-4 ms-4">
                    <UsersCard
                        reactIcon={
                            <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                        }
                        header="Invigilator"
                        statsValue={
                            countData?.invigilatorCount
                                ? countData?.invigilatorCount
                                : 0
                        }
                    />
                </Col>
                <Col md={3} className="me-4 ms-4">
                    <UsersCard
                        reactIcon={
                            <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                        }
                        header="Candidates"
                        statsValue={
                            countData?.usersCount
                                ? countData?.usersCount
                                : 0
                        }
                    />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col md={3}>
                        <Select
                            options={[
                                { label: "10", value: 10 },
                                { label: "20", value: 20 },
                                { label: "30", value: 30 },
                                { label: "40", value: 40 },
                                { label: "50", value: 50 },
                            ]}
                            onChange={(e: any) => setPageSize(e)}
                            value={pageSize}
                            placeholder="Select Page Size"
                        />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CsrReportTable data={verificationData} pageNumber={pageNumber} pageSize={pageSize.value} />
                </Col>
            </Row>
            <Row>
                <Col>
                <Pagination size="sm">
                  {
                    totalCount > 0 && (
                      Array.from({ length: Math.ceil(totalCount / pageSize.value) }, (_, i) => i + 1).map((data, index) => {
                        return (
                          <Pagination.Item key={index} active={data === pageNumber} onClick={() => setPageNumber(data)}>{data}</Pagination.Item>
                        )
                      })
                    )
                  }
                </Pagination>
                </Col>
            </Row>
        </>
    )
}

export default Csr