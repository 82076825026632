import React, { useEffect, useState } from 'react'
import { Badge, Button, Image, Modal, Table } from 'react-bootstrap'
import { VerificationProcessEnum } from '../enum/VerficationProcess.enum'
import { ClientService } from '../services/client.service'
import moment from 'moment'

interface IUserModal {
    show: any,
    handleClose: any
    shiftId: any
}

export default function UserModal(props: IUserModal) {

    const [userData, setUserData] = useState<any>();

    const getUserDataByShiftId = async () => {
        await ClientService.getDatabyUserId(props.shiftId, props.show).then((res) => {
            if (res.status === 200) {
                if (res.status === 200) {
                    console.log(res.data, "userData")
                    setUserData(res.data)
                }
            }
        })
    }

    useEffect(() => {
        getUserDataByShiftId();
    }, [props.shiftId, props.show])

    return (
        <>
            <Modal
                show={props.show ? true : false}
                onHide={props.handleClose}
                size="lg"
                animation={true}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h5>
                            User Details
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <div style={{ width: 100, height: 100 }}>
                                <Image src={userData?.user?.avatar} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Name:</p>
                            <p className="flex-grow-1">{userData?.user?.name}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Roll No.:</p>
                            <p className="flex-grow-1">{userData?.user?.rollNo}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Gender:</p>
                            <p className="flex-grow-1">{userData?.user?.gender}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>City:</p>
                            <p className="flex-grow-1">{userData?.user?.city}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>State:</p>
                            <p className="flex-grow-1">{userData?.user?.state}</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5 className="mb-2">
                            Exam Details
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Exam Name:</p>
                            <p className="flex-grow-1">{userData?.exam?.name}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Live:</p>
                            <p className="flex-grow-1">{userData?.exam?.isLive ? <span className="fw-bold text-success">Live</span> : <span className="fw-bold text-danger">Not Live</span>}</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5 className="mb-2">
                            Shift Details
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Shift Name:</p>
                            <p className="flex-grow-1">{userData?.shift?.shiftName}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Start Time:</p>
                            <p className="flex-grow-1">{moment(userData?.shift?.startTime).format("DD-MM-YYYY hh:mm a")}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>End Time:</p>
                            <p className="flex-grow-1">{moment(userData?.shift?.endTime).format("DD-MM-YYYY hh:mm a")}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-2">
                        <h5>
                            Verifications
                        </h5>
                        <div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Center Id</th>
                                        <th>Invigilator</th>
                                        <th>Live Photo</th>
                                        <th>Verification Id</th>
                                        <th
                                            colSpan={Object.keys(VerificationProcessEnum).length}
                                        >
                                            Verification
                                        </th>
                                    </tr>
                                    <tr className="text-muted fs-12">
                                        <th colSpan={5}></th>
                                        <th>
                                            Fingerprint
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userData?.verifications && userData?.verifications.map((data: any, index: number) => {
                                        const verificationDetails = data?.verificationDetails;
                                        const fingerprint = verificationDetails.find((ver: any) => ver.method == "fingerprint")

                                        return (
                                            <tr>
                                                <td className='text-align-center'>{index + 1}</td>
                                                <td className='text-align-center'>{data?.centerId}</td>
                                                <td className='text-align-center'>{data?.invigilatorId?.userName}</td>
                                                <td>
                                                    <div style={{ width: 70, height: 70 }}>
                                                        <Image src={data?.userLivePhoto} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} />
                                                    </div>
                                                </td>
                                                <td className='text-align-center'>{data?.verificationId}</td>
                                                <td>
                                                    {fingerprint?.image &&
                                                        <div style={{ width: 50, height: 50 }}>
                                                            <Image src={fingerprint?.image} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} />
                                                        </div>
                                                    }
                                                    <span className="fs-12 cursor-pointer">
                                                        {fingerprint && fingerprint?.manuallyVerified == true ? (
                                                            <Badge bg="warning">
                                                                Manually Verified
                                                            </Badge>
                                                        ) : fingerprint && fingerprint?.success == true ? (
                                                            <>
                                                                <Badge bg="success">
                                                                    Verified
                                                                </Badge>
                                                            </>
                                                        ) : (
                                                            <Badge bg="danger">
                                                                Not Verified
                                                            </Badge>
                                                        )
                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}
