import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import mainRoutes from '../component/routes/routes';
import SideBar from '../component/SideBar/SideBar';
import TopBar from '../component/TopBar/TopBar';




export default function PrivateRoutes() {

    return (
        <>
            <TopBar
                menuData={mainRoutes}
                panelName="User"
            />

            <Container>
                <Outlet />
            </Container>
        </>
    )
}