import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Pagination, Row, Table } from 'react-bootstrap'
import toast from 'react-hot-toast';
import Select from "react-select"
import { UsersCard } from '../../component/Card/Statistics.card';
import { ClientService } from '../../component/services/client.service';
import CsrReportTable from '../../component/table/CsrReportTable';
import downloadjs from 'downloadjs';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import UserModal from '../../component/modals/User.modal';

export default function CenterCount(){


    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const [verificationData, setVerificationData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);


    const [centerData, setCenterData] = useState<any>([])

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getCenterCount = async () => {
        await ClientService.getCenterCount(selectValue)
            .then((res) => {
                if (res.status === 200) {
                    setCenterData(res.data.count);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            });
    };


    useEffect(() => {
        if (selectValue) {
            setVerificationData([])
            getCenterCount();
        }
    }, [selectValue])

    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <div className="d-flex justify-content-start pt-4 mb-4">
                <Select
                    options={shifts}
                    onChange={(e: any) => setSelectValue(e.value)}
                    placeholder="Select shift"
                />

            </div>

            <Row>
                <Col>
                        <Table striped bordered hover size="sm" responsive>
                            <thead>
                                <tr>
                                    <th>Center Name</th>
                                    <th>Center Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {centerData.map((data: any, index: any) => {
                                    return (
                                        <tr>
                                            <td>{data.center}</td>
                                            <td>{data.count}</td>
                                        </tr>
                                    )
                                })}
                                <tr></tr>
                            </tbody>
                        </Table>
                </Col>
            </Row>
        </>
    )
}