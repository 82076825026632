import { faGear, faArrowRightArrowLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Dropdown, DropdownButton, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import IRouter from '../Interface/IRouter';
import './Topbar.css';

interface ITopBar {
  menuData: IRouter[],
  panelName: string,
  baseUrl?: string
}

export default function TopBar({ menuData, panelName, baseUrl }: ITopBar) {

  let navigate = useNavigate();
  const location = useLocation();

  const showAllowedMenu = menuData.filter((routes) => routes.navbarShow === true)

  const handleLogout = () => {
    sessionStorage.removeItem("authKey");
    navigate("/login")
  };

  return (
    <>
      <Navbar className="pl-3 d-flex shadow-sm" bg='dark' variant='light'>
        <Navbar.Brand className="ms-2 fw-bold" style={{ color: "white" }}>Nixauth-Client</Navbar.Brand>
        <div className="d-flex justify-content-right align-items-center me-2">
          <div className="d-flex justify-content-left me-5">
            {
              showAllowedMenu.map((data, index) => {
                return (
                  <>
                    <div key={index}
                      className={"xrg-nav-link" + (data.path == location.pathname.split("/")[2] ? " xrg-nav-selected" : " ")}
                      onClick={() => navigate(data.path)}
                    >
                      <div className='xrg-nav-item mt-3'>
                        <div className="xrg-nav-close d-flex align-items-center mb-3">
                          <span
                            className="nav-btn me-2"
                            style={{ cursor: "pointer", }}
                          >
                            {data.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
          <div style={{ marginLeft: "80rem" }}>
            <Button className="m-2 fw-bold" size="sm" onClick={handleLogout}>Logout</Button>
          </div>
        </div>
      </Navbar>
    </>
  );
}
