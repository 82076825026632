import React from "react"

// Other Imports

// bootstrap
import { Button, Container, Modal } from "react-bootstrap";

// components

// Views
import LoginBox from './LoginBox/LoginBox';
// Icons

// CSS
import "./Login.css";


export default function Login() {
    const [show, setShow] = React.useState(true);
    return (
        <div className="bg-light-primary minh100">
            <Container fluid className="d-flex justify-content-center">
                <LoginBox />
            </Container>
        </div>
    )
}