import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import Auth from './component/Auth/auth';
import IRouter from './component/Interface/IRouter';
import mainRoutes from './component/routes/routes';
import PrivateRoutes from './View/Private.index';
import Login from './View/Public/Login/Login';

function PrivateRouter({ children }: any) {
  const auth = Auth.checkAuth();
  auth ? <PrivateRoutes /> : <Navigate to="/login" />;
  return <PrivateRoutes />;
}


const Router = () => {
  return (
    <>
      <Routes>
        {/*path is dashboard the render privateRounter */}
        <Route element={<PrivateRouter />}>
          {mainRoutes.map((data: IRouter) => {
            // data will follow poperty of IRouter
            return (
              <Route
                path={data.path + "/*"}
                element={<data.element />}
              />
            )
          })}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  )
}

export default Router
